import React from "react"
import { Helmet } from "react-helmet"
import { Box } from "~common/components/Primitives"
import Page from "~common/components/Page"
import Section from "~common/components/Section"
import Typography from "~common/components/Typography"

const CreditsPage = () => {
  return (
    <Page
      seo={{ title: "Credits", description: "Thanks to all Almagesto backers" }}
    >
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Caudex&family=Lateef&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Page.Header downArrows>
        <Page.Header.Title>
          <Box as="span" sx={{ textTransform: "none" }}>
            Thanks
            <br />
            <Box as="span" sx={{ fontFamily: "greek" }}>
              Ευχαριστώ
            </Box>
            <br />
            <Box as="span" sx={{ fontFamily: "arabic" }}>
              شكرا
            </Box>
          </Box>
        </Page.Header.Title>
        <Page.Header.Subtitle>
          Thank you for supporting our project.
        </Page.Header.Subtitle>
        <Page.Header.Description>Grazie!</Page.Header.Description>
      </Page.Header>
      <Page.Body>
        <Section bg="primary" color="accent">
          <Section.Header>Kickstarter Backers</Section.Header>
          <Section.Body>
            <Box
              sx={{
                mx: [4, 5, "16%"],
                my: [4, 5],
                bg: "accent",
                display: "grid",
                placeItems: "center",
                gridTemplateColumns: ["1fr", "1fr", "repeat(3, 1fr)"],
                columnGap: "1px",
                rowGaps: 0,
              }}
            >
              {[
                "Liam Arbuckle",
                "Francesca V",
                "Julia Taylor",
                "Korine Powers",
                "Maurizio Gagliano",
                "Kat Crow",
                "Robert Levy",
                "Bert Maes",
                "Cathy Atela",
                "Rich Jeffery",
                "Judith Mackiewicz",
                "Susane Moua",
                "Bert Maes",
                "Justin Wilder",
                "Juan Pablo Vidal",
                "Dagmar Baumann",
                "Lea Janssen",
                "David Lo",
                "Richard Libera",
                "Dean Tantillo",
                "Joel Imaizumi",
                "Peach Hatt-Beattie",
                ".",
                ".",
              ].map((backer, i) => (
                <Typography
                  key={i}
                  variant="normal"
                  sx={{
                    textAlign: "center",
                    bg: "primary",
                    color: "secondary",
                    width: "100%",
                    height: "100%",
                    lineHeight: 2,
                  }}
                >
                  <Box
                    as="span"
                    sx={{ visibility: i < 22 ? "visible" : "hidden" }}
                  >
                    {backer}
                  </Box>
                </Typography>
              ))}
            </Box>
          </Section.Body>
        </Section>
      </Page.Body>
    </Page>
  )
}

export default CreditsPage
